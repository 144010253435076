import { Autocomplete, Button, Checkbox, Chip, DialogActions, DialogContent, Divider, FormControlLabel, Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { BootstrapDialog, BootstrapDialogTitle } from "./HelperComponents/DialogComponents";
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks";
import { closeAcceptPopup } from "../../Store/OpenRegistrationsSlice";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { store, RootState } from "../../../../../../Infrastructure/Store/store";
import { CustomerOverviewResponseDto } from "../../../../Customers/AllCustomers/Models/CustomerOverviewResponseDto";
import { useEffect, useState } from "react";
import { clearGeneratedCustomerId, toggleShowAllCustomers } from "../../../../Customers/Store/CustomersSlice";
import { CustomSwitch } from "../../../../../../Layout/Switch/CustomSwitch";
import { NavLink, useLocation } from "react-router-dom";
import { DefaultResponse } from "../../../../../../Infrastructure/Api/Model/DefaultResponse";
import { UserDetailResponseDto } from "../../../UserEdit/Models/UserDetailResponseDto";
import { LoadingButton } from "@mui/lab";
import { OpenRegistrationAcceptUserData } from "./HelperComponents/OpenRegistrationAcceptUserData";
import { ListboxComponent, StyledPopper } from "../../../../../../Layout/Inputs/AutoCompleteVirtualizationHelper";


export const OpenRegistrationAcceptPopup = () => {
    const theme = useTheme();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { visible, acceptUserId, acceptEmail } = useAppSelector(store => store.openRegistrations.acceptPopup);
    const showAllCustomers = useAppSelector(store => store.customers.showAllCustomers);
    const language = useAppSelector(store => store.settings.language);
    const { isDepragAmbergMember } = useAppSelector(store => store.authentication);
    const generatedCustomerId = useAppSelector(store => store.customers.generatedCustomerId);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [selectedCustomer, setSelectedCustomer] = useState<string>("");
    const [availableCustomers, setAvailableCustomers] = useState<{ Id: number, CompanyName: string }[]>();
    const [customerError, setCustomerError] = useState<boolean>(false);

    const [sendEmailToCustomer, setSendEmailToCustomer] = useState<boolean>(true);

    const [submitting, setSubmitting] = useState<boolean>(false);
    const customerId = useAppSelector(store => store.authentication.customerId);

    const close = () => {
        setSelectedCustomer("")
        dispatch(closeAcceptPopup())
    }

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["customers"],
        queryFn: async () => await fetchMyDepragApi<CustomerOverviewResponseDto>(
            { showAll: isDepragAmbergMember ? showAllCustomers : false, preferredLanguageIso: language },
            "customers/overview/" + customerId,
            'POST',
            dispatch,
            store.getState() as RootState)
    });

    const { isLoading: isLoadingUser, data: dataUser, refetch: refetchUser } = useQuery({
        queryKey: ["userDetail"],
        queryFn: async () => await fetchMyDepragApi<UserDetailResponseDto>({},
            'users/' + acceptUserId,
            'GET',
            dispatch,
            store.getState() as RootState),
        enabled: acceptUserId !== undefined && acceptUserId > 0
    });

    const submit = async () => {
        // if(selectedCustomer.trim().length > 0 && availableCustomers && availableCustomers.filter(x => x.CompanyName === selectedCustomer)[0])
        setSubmitting(true);
        var dataToPost = {
            userIdToAccept: acceptUserId,
            assignToCustomerWithId: availableCustomers?.filter(x => x.CompanyName === selectedCustomer)[0].Id ?? 1,
            sentEmailToCustomer: sendEmailToCustomer
        };
        await fetchMyDepragApi<DefaultResponse>(dataToPost, "users/openregistrations/accept", 'POST', dispatch, store.getState() as RootState, "SuccessfullyAcceptedRegistration");
        setSubmitting(false);
        close();
    }

    useEffect(() => {
        refetch()
    }, [showAllCustomers, location, generatedCustomerId])


    useEffect(() => {
        if (data && data.Items && data.Items.length > 0) {
            setAvailableCustomers(data.Items.map(c => {
                return {
                    Id: c.Id,
                    CompanyName: `${c.CompanyName} ${c.SapCustomerId && c.SapCustomerId.trim().length > 0 ? `(${c.SapCustomerId})` : ''}`
                }
            }));
        }
    }, [data])

    useEffect(() => {
        if (availableCustomers && availableCustomers.length > 0 && generatedCustomerId) {
            setSelectedCustomer(availableCustomers.filter(x => x.Id === generatedCustomerId)[0] ? availableCustomers.filter(x => x.Id === generatedCustomerId)[0].CompanyName : "");
        }

    }, [availableCustomers, generatedCustomerId, location])

    useEffect(() => {
        if (selectedCustomer && selectedCustomer.trim().length > 0) {
            dispatch(clearGeneratedCustomerId())
        }
    }, [selectedCustomer])

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <BootstrapDialog
                        scroll="paper"
                        fullScreen={fullScreen}
                        maxWidth={'md'}
                        PaperProps={{ sx: { minWidth: '20%', minHeight: '30%' } }}
                        onClose={close}
                        aria-labelledby="customized-dialog-title"
                        open={visible}
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" >
                            <Typography variant="h5" fontWeight={500}>{getTranslatedText("AcceptRegistration")}</Typography>
                        </BootstrapDialogTitle>
                        <DialogContent>
                            <Grid container direction={'row'} spacing={2}>
                                {dataUser && !isLoadingUser && <Grid item xs={12} sx={{ mt: 0, mb: 0 }}>
                                    <OpenRegistrationAcceptUserData user={dataUser} />
                                </Grid>}

                                <Grid item xs={12} sx={{ mt: 0, mb: 2 }}>
                                    <Typography><span dangerouslySetInnerHTML={{ __html: getTranslatedText("PleaseSetCustomerForUser", [`${dataUser?.FirstName} ${dataUser?.LastName}`, `${dataUser?.FirstName} ${dataUser?.LastName}`]) }}></span></Typography>
                                </Grid>



                                {isDepragAmbergMember && <Grid item xs={12} my={'auto'} sx={{ textAlign: 'right' }}>
                                    <FormControlLabel control={<CustomSwitch />} checked={showAllCustomers} onChange={() => dispatch(toggleShowAllCustomers())} label={getTranslatedText("ShowAllCustomers")} />
                                </Grid>}

                                <Grid item xs={12}>
                                    <Autocomplete
                                        disabled={isLoading || !data || isLoadingUser || !dataUser}
                                        disableListWrap
                                        PopperComponent={StyledPopper}
                                        ListboxComponent={ListboxComponent}

                                        renderOption={(props, option, state) =>
                                            [props, option, state.index] as React.ReactNode
                                        }
                                        noOptionsText={getTranslatedText("NoCustomersAvailable")}
                                        fullWidth

                                        id="customers"
                                        options={availableCustomers ? availableCustomers.map(option => option.CompanyName) : []}
                                        value={selectedCustomer}
                                        ListboxProps={{
                                            style: { zIndex: 10002 }
                                        }}
                                        onChange={(event: any, newValue: string | null) => {
                                            if (newValue) {
                                                setCustomerError(false)
                                                setSelectedCustomer(newValue)
                                            }
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            fullWidth
                                            error={customerError}
                                            helperText={customerError ? getTranslatedText("PleaseProvideACustomer") : ''}
                                            label={getTranslatedText("CustomerName")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider>
                                        <Chip label={getTranslatedText("Or")} />
                                    </Divider>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        <Button fullWidth color="darky" variant="outlined" component={NavLink} to={'/customers/create'}>{getTranslatedText("CreateCustomer")}</Button>
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 3, textAlign: 'right' }}>
                                    <FormControlLabel control={<Checkbox disabled={isLoading || !data || isLoadingUser || !dataUser} checked={sendEmailToCustomer} onChange={() => setSendEmailToCustomer(o => !Boolean(o))} />} label={<span dangerouslySetInnerHTML={{ __html: getTranslatedText("SendEmailToUser", [acceptEmail]) }}></span>} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="darky" onClick={close}>{getTranslatedText("CommonCancel")}</Button>
                            <LoadingButton loading={submitting} disabled={selectedCustomer.trim().length === 0 || isLoading || !data || isLoadingUser || !dataUser} variant="contained" color="success" onClick={() => {
                                submit();
                            }} autoFocus>{getTranslatedText("AcceptUser")}</LoadingButton>
                        </DialogActions>
                    </BootstrapDialog>
                </>
            }
        </LanguageConsumer>
    )
}

import { ResellerDetailResponseDto } from "./ResellerDetailResponseDto";

export const EmptyResellerAddRequestDto = {
    Email: '',
    Id: 0,
    Name: '',
    ResellerAddress: '',
    Countries: [],
    Customer: { CompanyName: '', CountryName: '', City: '', Id: 0, SapCustomerId: '', NumberOfUsers: 0, NumberOfActiveGoldLicences: 0, InternalId: "" },
    MonthlyPrice: 0,
    YearlyPrice: 0,
    TaxMonthly:0,
    TaxYearly:0,
    Currency: "€"
} as ResellerDetailResponseDto
import * as z from "zod";
import i18next from "i18next";
import { zodI18nMap } from "zod-i18n-map"
import de from "../../../../../Infrastructure/Internationalisation/ValidationJSON/de.json";
import en from "../../../../../Infrastructure/Internationalisation/ValidationJSON/en.json";

const isDe = navigator.language.toLowerCase().startsWith("de");

i18next.init({
    lng: isDe ? "de" : "en",
    resources: {
      de: { zod: de },
      en: { zod: en }
    },
  });
  z.setErrorMap(zodI18nMap);

export const ResellerAddValidator = z.object({
    Name: z.string().nonempty().max(100),
    ResellerAddress: z.string().nonempty().max(500),
    Email: z.string().nonempty().email().max(255),
    Currency: z.string().nonempty().max(5),
    MonthlyPrice: z.number(),
    YearlyPrice: z.number(),    
    TaxMonthly: z.number(),
    TaxYearly: z.number()
})
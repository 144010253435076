import { Chip } from "@mui/material";
import { useState } from "react";
import toast from "react-hot-toast";
import HtmlParser from "react-html-parser";
import { PiCheckCircleLight, PiCopyLight } from "react-icons/pi";
import { LanguageConsumer, getTranslation } from "../Infrastructure/Internationalisation/TranslationService";
import { HtmlTooltip } from "./Tooltips/HtmlTooltip";

interface CopyChipProps {
    label: string,
    color?: 'default' | 'success' | 'info' | 'warning' | 'whitey' | 'error' | 'darky' | 'gold',
    isCopyEnabled: boolean,
    showIconAfterCopy?:boolean,
    followCursor?:boolean,
    size?: 'small' | 'medium',
    sx?: any,
    variant?: 'filled' | 'outlined'
}

export const CopyChip = (props: CopyChipProps) => {
    const [isCopied, setIsCopied] = useState<boolean>(false);
    
    const copyToClipboardAndShowToastMessage = async () => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(props.label);
        } else {
            return document.execCommand('copy', true, props.label);
        }
    }

    const handleCopyClick = () => {
        copyToClipboardAndShowToastMessage()
        .then(() => {
          toast.success(getTranslation("SuccessfullyCopiedToClipboard", [props.label]))
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 3500);
        })
        .catch((err) => {
          
        });
    }

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
                <HtmlTooltip followCursor={props.followCursor ? props.followCursor : true} title={props.isCopyEnabled ? HtmlParser(getTranslatedText('CopyToClipboard')) : ''}>
                <Chip variant={props.variant ? props.variant : 'filled'} size={props.size ? props.size : 'medium'} onClick={handleCopyClick} label={props.label} onDelete={handleCopyClick} deleteIcon={isCopied && props.showIconAfterCopy ? <PiCheckCircleLight size={20} color="#32992c" /> : <PiCopyLight />} sx={{cursor: props.isCopyEnabled ? 'pointer' : 'default', ...props.sx}} color={props.color ? props.color : 'default'} />
                </HtmlTooltip>
            </>
            }
        </LanguageConsumer>
    )
}
import { Chip, Grid, Skeleton, Switch, Typography } from "@mui/material";
import { LanguageConsumer } from "../../../../../../../../Infrastructure/Internationalisation/TranslationService"
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../../../../../Infrastructure/Api/ApiBaseCall";
import { useAppDispatch, useAppSelector } from "../../../../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../../../../Infrastructure/Store/store";
import { CustomBox } from "../../../../../../../../Layout/CustomBox";
import { PaidLicencesDetailOverviewDto } from "../../../../Model/PaidLicencesDetailOverviewDto";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { PreventActivationOfOrdersRequestDto } from "../../../../Model/PreventActivationOfOrdersRequestDto";
import { CustomSwitch } from "../../../../../../../../Layout/Switch/CustomSwitch";

interface PaidLicenseDetailsStatusGeneralProsp {
    id: string | undefined
}

export const PaidLicenseDetailsStatusGeneral = (props: PaidLicenseDetailsStatusGeneralProsp) => {
    const dispatch = useAppDispatch();
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
    const { isLoading, data, refetch } = useQuery({ queryKey: ["paidLicenseDetail"], queryFn: async () => await fetchMyDepragApi<PaidLicencesDetailOverviewDto>({ payedLicenceId: parseInt(props.id as string) }, 'licences/ordernumber/detail', 'POST', dispatch, store.getState() as RootState)});
    const {refetch: updatePreventActivationOfOrder, isFetching: isUpdatingPreventActivationOfOrder} = useQuery({ queryKey: ["preventActivationOfOrder"], queryFn: async () => await fetchMyDepragApi({ DisableCheckOfOrderStatusForUnlock: disableCheckOfOrderStatusForUnlock, Id: parseInt(props.id as string) }as PreventActivationOfOrdersRequestDto, 'licences/ordernumber/editpreventactivation', 'POST', dispatch, store.getState() as RootState, "SuccessfullySaved"),enabled: false});
    const [disableCheckOfOrderStatusForUnlock, setDisableCheckOfOrderStatusForUnlock] = useState<boolean>(false);
    
    //await fetchMyDepragApi(dataToPost, "settings/remindersettings/save", "POST", dispatch, store.getState(), "SuccessfullySavedSettings")
        
    
    const handleClick = (_e) => {
        updatePreventActivationOfOrder();
    }

    useEffect(() => {
        refetch();
    },[isUpdatingPreventActivationOfOrder, refetch])

    useEffect(() => {
        data && setDisableCheckOfOrderStatusForUnlock(data.DisableCheckOfOrderStatusForUnlock);
    },[isLoading, data])

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    {!isLoading && data ? <CustomBox elevation={0} sx={{ height: '100%', background: `radial-gradient(${!isDarkMode ? '#ffffffff' : 'rgba(29, 29, 29, 1)'} 0%,${!isDarkMode ? '#f3f3f3ff' : 'rgba(139, 139, 139, 1)'}  100%)` }}>

                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                <Typography fontWeight={700} variant="h6">{getTranslatedText("PaidLicensesDetailsGeneralTitle")}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container direction={'row'} spacing={2} sx={{mt:.5}}>
                            <Grid item xs={5} my={'auto'}>{getTranslatedText("PaidLicenceCompanyName")}</Grid>
                            <Grid item xs={7} my={'auto'}><Chip label={data.CompanyName} /></Grid>
                        </Grid>

                        <Grid container direction={'row'} spacing={2} sx={{mt:.5}}>
                            <Grid item xs={5} my={'auto'}>{getTranslatedText("PaidLicenseDetailsProductName")}</Grid>
                            <Grid item xs={7} my={'auto'}><Chip label={data.ProductName} /></Grid>
                        </Grid>

                        <Grid container direction={'row'} spacing={2} sx={{mt: .5}}>
                            <Grid item xs={5} my={'auto'}>{getTranslatedText("PaidLicenseDetailsOrderNumber")}</Grid>
                            <Grid item xs={7} my={'auto'}><Chip label={data.OrderNumber} /></Grid>
                        </Grid>

                        <Grid container direction={'row'} spacing={2} sx={{mt: .5}}>
                            <Grid item xs={5} my={'auto'}>{getTranslatedText("PaidLicenseDetailsOrderedLicenses")}</Grid>
                            <Grid item xs={7} my={'auto'}><Chip label={data.QuantityOrdered} /></Grid>
                        </Grid>
                        
                        <Grid container direction={'row'} spacing={2} sx={{mt: .5}}>
                            <Grid item xs={5} my={'auto'}>{getTranslatedText("OrderType")}</Grid>
                            <Grid item xs={7} my={'auto'}><Chip label={data.OrderType} /></Grid>
                        </Grid>

                        {data.IsOrderTypeWhereLicenceIsDisabledUntilDelivery && 
                        <Grid container direction={'row'} spacing={2} sx={{mt:.5}}>
                            <Grid item xs={5} my={'auto'} >{getTranslatedText("PaidLicensePreventActivationOfOrder")}</Grid>
                            <Grid item xs={7} container>
                               
                               
                                <Grid item xs={3} my={'auto'}>
                                    <CustomSwitch checked={disableCheckOfOrderStatusForUnlock} onClick={() => setDisableCheckOfOrderStatusForUnlock(prevValue => !prevValue)}  />
                                </Grid>



                                <Grid container item xs={9} >
                                    <Grid item xs={5} my={'auto'}>
                                        <LoadingButton onClick={handleClick} color="success" variant="contained" >{getTranslatedText('CommonSave')}</LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        }

                    </CustomBox> :
                        <Skeleton width={'100%'} height={300} sx={{ mt: -8 }} />
                    }
                </>
            }
        </LanguageConsumer>
    )
}
export async function base64ToFile(dataUrl: string, fileName: string): Promise<File> {

    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'plain/text' });
}

export const downloadFile = (fileToDownload: File, releaseKeyFileName: string, isTxt: boolean) => {
    var fr = new FileReader();
    fr.readAsDataURL(fileToDownload);

    var blob = new Blob([fileToDownload], { type: isTxt ? "text/plain" : "application/octet-stream" });

    var objectURL = window.URL.createObjectURL(blob);

    var link = document.createElement('a');
    link.href = objectURL;
    link.download = releaseKeyFileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMyDepragApi } from '../../../../../../../../../../Infrastructure/Api/ApiBaseCall';
import { RootState } from '../../../../../../../../../../Infrastructure/Store/store';
import { PaidLicencesDeactivateLicenceRequestDto } from '../../../../../../Model/PaidLicencesDeactivateLicenceRequestDto';


export type LicenseDeactivationState = {
  loading: boolean;
  success: boolean;
  error: boolean;
}

export const initialStateLicenseDeactivation: LicenseDeactivationState = {
  loading: false,
  success: false,
  error: false,
};

export const licenseDeactivate = createAsyncThunk('licenseDeactivation', async (request: PaidLicencesDeactivateLicenceRequestDto, thunkAPI) => {
  var response =  await fetchMyDepragApi<any>(request, 'licences/ordernumber/deactivate', 'POST', thunkAPI.dispatch, thunkAPI.getState() as RootState);
  return response;
});

export const licenseDeactivationSlice = createSlice({
  name: 'licenseDeactivation',
  initialState: initialStateLicenseDeactivation,
  reducers: {
    resetDeactivationBooleans: (state) => {
        state.success = false;
        state.error = false;
        state.loading = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(licenseDeactivate.fulfilled, (state, action) => {
      state.success = true;
      state.loading = false;
      state.error = false;

    }).addCase(licenseDeactivate.pending, (state) => {
        state.loading = true;
    }).addCase(licenseDeactivate.rejected, (state) => {
        state.loading = false;
        state.success = false;
        state.error = true;
    })
  }
});

export const { resetDeactivationBooleans } = licenseDeactivationSlice.actions;

export default licenseDeactivationSlice.reducer;

import { FiCpu, FiGift, FiHelpCircle, FiHome, FiMonitor, FiSettings, FiShoppingBag, FiUsers, FiYoutube } from "react-icons/fi";
import { LuFactory } from "react-icons/lu";
import { MyDepragClaim } from "../../../Features/MainMenus/Highlevel/Claims/Details/Models/ClaimsEditResponseDto";
import { useAppSelector } from "../../../Infrastructure/Store/hooks";
import { NavigationItem } from "../../Store/DrawerSlice";

export const useNavigationItems = () => {
  const claims = useAppSelector(store => store.authentication.myDepragClaims);


  const isHidden = (necessaryClaim: MyDepragClaim): boolean => {

    if (claims === undefined || claims.length === 0)
      return true;
    const hasCorrectClaim = Object.values(claims).includes(necessaryClaim);

    return !hasCorrectClaim;
  }

  const shouldHideLicences = (): boolean => {
    if (claims === undefined || claims.length === 0)
      return true;

    const lic1: any = MyDepragClaim.BackofficeLicencesByDevice;
    const lic2: any = MyDepragClaim.BackofficeLicencesByOrder;
    const lic3: any = MyDepragClaim.BackofficeLicencesByCompany;
    const lic4: any = MyDepragClaim.BackofficeMyGoldLicences;

    const hasCorrectClaim = claims.some(c => MyDepragClaim[c] === lic1 || MyDepragClaim[c] === lic2 || MyDepragClaim[c] === lic3 || MyDepragClaim[c] === lic4);
    return !hasCorrectClaim;
  }

  const NavigationItems = [
    { id: 1, disabled: false, name: 'MainMenuDashboard', route: '/', icon: <FiHome />, className: 'global-menu-dashbaord', isOpen: false, children: null },
    { id: 2, disabled: isHidden(MyDepragClaim.BackofficeCustomers), name: 'MainMenuCustomers', route: '/customers', icon: <LuFactory />, className: 'global-menu-webshop', isOpen: false, children: null },
    {
      id: 3, disabled: isHidden(MyDepragClaim.BackofficeUsers), name: 'MainMenuUsers', route: '/users', icon: <FiUsers />, className: 'global-menu-webshop', isOpen: false, children: [
        { id: 300, name: 'MainMenuWebshopAllUsers', route: '/users/allUsers', icon: 'people_alt', className: 'global-menu-webshop-screwdriving', isOpen: false, children: null },
        { id: 301, name: 'MainMenuWebshopOpenRegisters', route: '/users/openRegistrations', icon: 'people_alt', className: 'global-menu-webshop-airPressure', isOpen: false, children: null },
        { id: 302, name: 'MainMenuWebshopEmailConfirmation', route: '/users/WaitForEmailVerifications', icon: 'people_alt', className: 'global-menu-webshop-software', isOpen: false, children: null }
      ]
    },
    { id: 4, disabled: isHidden(MyDepragClaim.BackofficeVideoCourses), name: 'MainMenuVideoCourses', route: '/videoCourses/overview', icon: <FiYoutube />, className: 'global-menu-webshop', isOpen: false, children: null },
    {
      id: 5, disabled: shouldHideLicences(), name: 'MainMenuLicences', route: '/licences', icon: <FiMonitor />, className: 'global-menu-webshop', isOpen: false, children: [
        { id: 500, disabled: isHidden(MyDepragClaim.BackofficeLicencesByDevice), name: 'MainMenuLicenceByDevice', route: '/licences/byDevice', icon: 'people_alt', className: 'global-menu-webshop-screwdriving', isOpen: false, children: null },
        { id: 510, disabled: isHidden(MyDepragClaim.BackofficeLicencesByOrder), name: 'MainMenuLicenceByOrder', route: '/licences/byOrder', icon: 'people_alt', className: 'global-menu-webshop-screwdriving', isOpen: false, children: null },
        { id: 520, disabled: isHidden(MyDepragClaim.BackofficeLicencesByCompany), name: 'MainMenuLicenceByCompany', route: '/licences/byCompany', icon: 'people_alt', className: 'global-menu-webshop-screwdriving', isOpen: false, children: null },
        { id: 530, disabled: isHidden(MyDepragClaim.BackofficeMyGoldLicences), name: 'MainMenuMyGoldLicence', route: '/licences/myGold', icon: 'people_alt', className: 'global-menu-webshop-screwdriving', isOpen: false, children: null },
      ]
    },
    {
      id: 6, disabled: isHidden(MyDepragClaim.HighlevelClaim), name: 'MainMenuSoftware', route: '/software', icon: <FiCpu/>, className: 'global-menu-software', isOpen: false, children: [
        {id: 601, name: 'MainMenuSoftwareCockpit', route: '/software/cockpitcloud', icon: 'settings_alt', className: 'global-menu-software-cockpitcloud', isOpen: false, children: null}
      ]
    },
    { id: 7, disabled: isHidden(MyDepragClaim.BackofficeHelp), name: 'MainMenuHelp', route: '/help/overview', icon: <FiHelpCircle />, className: 'global-menu-webshop', isOpen: false, children: null },
    {
      id: 8, disabled: false, name: 'MainMenuSettings', route: '/settings', icon: <FiSettings />, className: 'global-menu-settings', isOpen: false, children: [
        { id: 801, name: 'MainMenuSettingsReminder', route: '/settings/reminder', icon: 'settings_alt', className: 'global-menu-settings-reminder', isOpen: false, children: null }
      ]
    },
    {
      id: 10, disabled: isHidden(MyDepragClaim.BackofficeProductManagement), name: 'MainMenuBackofficeProductManagement', route: '/resellers', icon: <FiShoppingBag />, className: 'global-menu-settings', isOpen: false, children: [
        { id: 1001, name: 'MainMenuHighlevelReseller', route: '/resellers', icon: 'settings_alt', className: 'global-menu-settings-reminder', isOpen: false, children: null },
        { id: 1002, name: 'MainMenuLegalDocuments', route: '/legalDocuments', icon: 'settings_alt', className: 'global-menu-settings-reminder', isOpen: false, children: null },
        { id: 1003, name: 'MainMenuProductSearch', route: '/productsearch', icon: 'settings_alt', className: 'global-menu-settings-reminder', isOpen: false, children: null },
      ]
    },
    {
      id: 9, disabled: isHidden(MyDepragClaim.HighlevelClaim), name: 'MainMenuHighlevel', route: '/highlevel', icon: <FiGift />, className: 'global-menu-settings', isOpen: false, children: [
        { id: 901, name: 'MainMenuHighlevelAllClaims', route: '/highlevel/allclaims', icon: 'settings_alt', className: 'global-menu-settings-reminder', isOpen: false, children: null },
        { id: 902, name: 'MainMenuEmailTriggerRules', route: '/highlevel/emailTriggerRules', icon: 'settings_alt', className: 'global-menu-settings-reminder', isOpen: false, children: null },
        { id: 903, name: 'MainMenuHighlevelLogs', route: '/highlevel/logs', icon: 'settings_alt', className: 'global-menu-settings-reminder', isOpen: false, children: null },
        { id: 904, name: 'MainMenuHighlevelMaintenance', route: '/highlevel/maintenance', icon: 'settings_alt', className: 'global-menu-settings-reminder', isOpen: false, children: null },
        { id: 905, name: 'MainMenuHighlevelExpertSettings', route: '/highlevel/expertSettings', icon: 'settings_alt', className: 'global-menu-highlevel-expertSettings', isOpen: false, children: null },
        { id: 906, name: 'MainMenuHighlevelReporting', route: '/highlevel/reporting', icon: 'settings_alt', className: 'global-menu-highlevel-expertSettings', isOpen: false, children: null },
      ]
    },
  ] as NavigationItem[];

  return {
    navigationItems: NavigationItems
  }
}
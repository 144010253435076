import { useQuery } from "@tanstack/react-query";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { ResellerDetailResponseDto } from "../Models/ResellerDetailResponseDto";
import { ResellerDetailRequestDto } from "../Models/ResellerDetailRequestDto";
import { CustomBox } from "../../../../../Layout/CustomBox";
import { Alert, Autocomplete, Button, Divider, Grid, TextField, Typography, useTheme } from "@mui/material";
import { setAppTitle } from "../../../../../Store/AppSlice";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { TextFieldFormControl } from "../../../../../Layout/Inputs/TextFieldFormControl";
import { useForm } from "react-hook-form";
import { EmptyResellerAddRequestDto } from "../Models/EmptyResellerAddRequestDto";
import { LoadingButton } from "@mui/lab";
import { CountryResponseDto } from "../../../Customers/CustomerCreate/Models/CountryResponseDto";
import { ResellerAddValidator } from "../Models/ResellerAddValidator";
import { zodResolver } from "@hookform/resolvers/zod";
import { ResellerEditRequestDto } from "../Models/ResellerEditRequestDto";
import { CustomerOverviewResponseDto } from "../../../Customers/AllCustomers/Models/CustomerOverviewResponseDto";
import { CustomerOverviewItemDto } from "../../../Customers/AllCustomers/Models/CustomerOverviewItemDto";
import { StyledPopper, ListboxComponent } from "../../../../../Layout/Inputs/AutoCompleteVirtualizationHelper";

export const ResellerEdit = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const PreferredIsoLanguageCode = useAppSelector(store => store.settings.language);
    const { id } = useParams();
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
    const theme = useTheme();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const customerId = useAppSelector(store => store.authentication.customerId);

    const { data, refetch, isLoading } = useQuery({
        queryKey: [id],
        queryFn: async () => await fetchMyDepragApi<ResellerDetailResponseDto>(
            { PreferredIsoLanguageCode: PreferredIsoLanguageCode, Id: parseInt(id!) } as ResellerDetailRequestDto,
            "resellers/detail",
            'POST',
            dispatch,
            store.getState() as RootState)
    });

    const { data: countries, refetch: refetchCountries } = useQuery({
        queryKey: ["countries"],
        queryFn: async () => await fetchMyDepragApi<CountryResponseDto>(
            { PreferredLanguageIsoCode: PreferredIsoLanguageCode },
            "basedata/countries",
            'POST',
            dispatch,
            store.getState() as RootState),
    });

    const { data: customerData, refetch: refetchCustomers } = useQuery({
        queryKey: ["customers"],
        queryFn: async () => await fetchMyDepragApi<CustomerOverviewResponseDto>(
            { showAll: true, preferredLanguageIso: language, selectedUserId: id },
            "customers/overview/" + customerId,
            'POST',
            dispatch,
            store.getState() as RootState)
    });

    useEffect(() => {
        refetch();
    })

    const [selectedCountries, setSelectedCountries] = useState(data ? data.Countries : [])
    const [selectedInputCountry, setSelectedInputCountry] = useState<string>('')
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerOverviewItemDto>();
    const [selectedInputCustomer, setSelectedInputCustomer] = useState<string>('')
    const [customerError, setCustomerError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setSelectedCountries(data ? data.Countries : [])
    }, [isLoading, data])

    const { register, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: data ? data : EmptyResellerAddRequestDto,
        values: data ? data : EmptyResellerAddRequestDto,
        resolver: zodResolver(ResellerAddValidator),
    });

    useEffect(() => {
        data && setSelectedCustomer(data.Customer);
    }, [customerData, data])

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuResellerDetailBreadcrumb')));
    }, [location, language, languageFileWasLoaded, dispatch]);

    useEffect(() => {
        refetchCountries();
        refetchCustomers();
    }, [refetchCountries, refetchCustomers, language, location]);

    const onSubmit = async (dataToSend: ResellerDetailResponseDto) => {
        setIsSubmitting(true);
        
        try {
            if (!selectedCustomer)
                return setCustomerError(true);

            if (!customerError) {
                await fetchMyDepragApi(
                    {
                        Id: data && data.Id,
                        Name: dataToSend.Name,
                        ResellerAddress: dataToSend.ResellerAddress,
                        Email: dataToSend.Email,
                        SelectCountryIds: selectedCountries.map(x => x.Id),
                        CustomerId: selectedCustomer && selectedCustomer.Id,
                        MonthlyPrice: dataToSend.MonthlyPrice,
                        YearlyPrice: dataToSend.YearlyPrice,
                        Currency: dataToSend.Currency,
                        TaxYearly: dataToSend.TaxYearly,
                        TaxMonthly: dataToSend.TaxMonthly
                    } as ResellerEditRequestDto,
                    "resellers/Edit",
                    "POST",
                    dispatch,
                    store.getState() as RootState,
                    getTranslation("ResellerSuccessEdit")
                );
                refetch();
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
                    <Grid item xs={12} sx={{ mb: 2, mx: 1 }}>
                        <Button color="darky" component={NavLink} to={'/resellers'} variant="outlined">
                            {getTranslatedText("Prev")}
                        </Button>
                    </Grid>

                    <CustomBox elevation={0} sx={{
                        mx: 3,
                        height: '100%',
                        width: '100%',
                        backgroundSize: 'cover',
                        backgroundBlendMode: 'multiply',
                        backgroundColor: isDarkMode ? "transparent" : "#fff",
                        border: `1px solid ${theme.palette.cardBorder.main}`
                    }}>

                        {data && data.Id === 1 &&
                            <Grid item xs={12} sx={{ textAlign: "left" }}>
                                <Alert severity="warning">{getTranslatedText("CanNotEditDAM")}</Alert>
                                <Divider sx={{ my: 3, mb: 2 }} />
                            </Grid>}

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <fieldset disabled={data && data.Id === 1} style={{ border: 'transparent' }}>
                                <Grid item xs={12} container direction={"row"} spacing={3}>

                                    <Grid item xs={2} minWidth={135} container alignContent={'center'} >
                                        <Typography fontWeight={700}>{getTranslatedText('ResellerId')}:</Typography>
                                    </Grid>

                                    <Grid item xs={10} container direction={"row"} spacing={3} >
                                        <Grid item xs={12}>
                                            <TextField value={data ? data.Id : ''} disabled sx={{ width: 50 }} />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                        <Typography>{getTranslatedText('ResellerCompanyName')}:</Typography>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <TextFieldFormControl
                                            inputProps={{
                                                "data-testid": "resellers-ResellerCompanyName"
                                            }}
                                            labelText=''
                                            disabled={isLoading || isSubmitting}
                                            propertyName="Name"
                                            register={register}
                                            errors={errors}
                                        />
                                    </Grid>

                                    <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                        <Typography>{getTranslatedText('ResellerEmail')}:</Typography>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <TextFieldFormControl
                                            inputProps={{
                                                "data-testid": "resellers-ResellerEmail"
                                            }}
                                            labelText=''
                                            disabled={isLoading || isSubmitting}
                                            propertyName="Email"
                                            register={register}
                                            errors={errors}
                                        />
                                    </Grid>

                                    <Grid item xs={2} minWidth={135} mt={2}>
                                        <Typography>{getTranslatedText('ResellerCountryName')}:</Typography>
                                    </Grid>

                                    <Grid item xs={10} container direction={'column'} >
                                        <Autocomplete
                                            multiple
                                            disableListWrap
                                            PopperComponent={StyledPopper}
                                            ListboxComponent={ListboxComponent}
                                            renderOption={(props, option, state) =>
                                                [props, `${option.CountryName}`, state.index] as React.ReactNode
                                            }
                                            options={countries && countries.Items ? countries.Items : []}
                                            getOptionLabel={(option) => option ? option.CountryName : ''}
                                            defaultValue={selectedCountries}
                                            value={selectedCountries ? selectedCountries : []}
                                            onChange={(_e, value) => setSelectedCountries(value)}
                                            filterSelectedOptions
                                            renderInput={(params) => (<TextField {...params} />)}
                                            disabled={(isLoading || isSubmitting) || (data && data.Id === 1)}
                                            inputValue={selectedInputCountry}
                                            onInputChange={(_e, input) => setSelectedInputCountry(input)}
                                            isOptionEqualToValue={(option, value) => option.Id === value.Id}
                                        />
                                    </Grid>

                                    <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                        <Typography>{getTranslatedText('ResellerAddress')}:</Typography>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <TextFieldFormControl
                                            inputProps={{
                                                "data-testid": "resellers-ResellerAddress"
                                            }}
                                            labelText=''
                                            disabled={isLoading || isSubmitting}
                                            propertyName="ResellerAddress"
                                            register={register}
                                            errors={errors}
                                        />
                                    </Grid>

                                    <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                        <Typography>{getTranslatedText('MonthlyPrice')}:</Typography>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <TextFieldFormControl
                                            type="number"
                                            inputProps={{
                                                "data-testid": "resellers-MonthlyPrice",
                                            }}
                                            labelText=''
                                            disabled={isLoading || isSubmitting}
                                            propertyName="MonthlyPrice"
                                            register={register}
                                            registerOptions={{ valueAsNumber: true }} 
                                            errors={errors}
                                            shrink={true}
                                        />
                                    </Grid>

                                    <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                        <Typography>{getTranslatedText('YearlyPrice')}:</Typography>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <TextFieldFormControl
                                            type="number"
                                            inputProps={{
                                                "data-testid": "resellers-YearlyPrice",
                                            }}
                                            labelText=''
                                            disabled={isLoading || isSubmitting}
                                            propertyName="YearlyPrice"
                                            register={register}
                                            registerOptions={{ valueAsNumber: true }} 
                                            errors={errors}
                                            shrink={true}
                                        />
                                    </Grid>


                                    <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                        <Typography>{getTranslatedText('TaxMonthly')}:</Typography>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <TextFieldFormControl
                                            type="number"
                                            inputProps={{
                                                "data-testid": "resellers-TaxMonthly",
                                            }}
                                            labelText=''
                                            disabled={isLoading || isSubmitting}
                                            propertyName="TaxMonthly"
                                            register={register}
                                            registerOptions={{ valueAsNumber: true }} 
                                            errors={errors}
                                            shrink={true}
                                        />
                                    </Grid>

                                    <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                        <Typography>{getTranslatedText('TaxYearly')}:</Typography>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <TextFieldFormControl
                                            type="number"
                                            inputProps={{
                                                "data-testid": "resellers-TaxYearly",
                                            }}
                                            labelText=''
                                            disabled={isLoading || isSubmitting}
                                            propertyName="TaxYearly"
                                            register={register}
                                            registerOptions={{ valueAsNumber: true }} 
                                            errors={errors}
                                            shrink={true}
                                        />
                                    </Grid>



                                    <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                        <Typography>{getTranslatedText('Currency')}:</Typography>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <TextFieldFormControl
                                            inputProps={{
                                                "data-testid": "resellers-Currency",
                                                maxLength: 5
                                            }}
                                            labelText=''
                                            disabled={isLoading || isSubmitting}
                                            propertyName="Currency"
                                            register={register}
                                            registerOptions={{ valueAsNumber: false }} 
                                            errors={errors}
                                        />
                                    </Grid> 
                                </Grid>

                                <Grid container direction={"row"} spacing={2} sx={{ mt: 2 }}>
                                    <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                        <Typography>{getTranslatedText('ResellerEditCustomerName')}:</Typography>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <Autocomplete
                                            disableListWrap
                                            disableClearable
                                            PopperComponent={StyledPopper}
                                            ListboxComponent={ListboxComponent}
                                            renderOption={(props, option, state) => option &&
                                                [props, `${option.CompanyName} [${option.SapCustomerId}]${option.CountryName && ', ' + option.CountryName}`, state.index]}
                                            disabled={(isLoading || isSubmitting) || (data && data.Id === 1)}
                                            noOptionsText={getTranslatedText("NoCustomersAvailable")}
                                            fullWidth
                                            id="customers"
                                            isOptionEqualToValue={(option, value) => {
                                                return option.CompanyName === value.CompanyName
                                                    && option.CountryName === value.CountryName
                                                    && option.Id === value.Id
                                                    && option.SapCustomerId === value.SapCustomerId;
                                            }}
                                            options={customerData ? customerData.Items : []}
                                            value={selectedCustomer ? selectedCustomer : {} as CustomerOverviewItemDto}
                                            getOptionLabel={x => `${x.CompanyName ?? ''} [${x.SapCustomerId ?? ''}]${x.CountryName ? ', ' + x.CountryName : ''}`}
                                            onChange={(_event, value) => { setSelectedCustomer(value) }}
                                            inputValue={selectedInputCustomer}
                                            onInputChange={(e, input) => setSelectedInputCustomer(input)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    error={customerError}
                                                    helperText={customerError ? getTranslatedText('CustomerIsEmpty') : ''}
                                                />}
                                        />
                                    </Grid>
                                </Grid>

                                {data && data.Id !== 1 &&
                                    <Grid container direction={"row"} spacing={2} sx={{ mt: 2 }}>
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                type='submit'
                                                color="success"
                                                fullWidth
                                                variant="contained"
                                                disabled={isLoading || isSubmitting}
                                                onClick={() => !selectedCustomer && setCustomerError(true)}
                                            >
                                                {getTranslatedText("CommonSave")}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                }
                            </fieldset>
                        </form>
                    </CustomBox>
                </Grid>
            }

        </LanguageConsumer>
    )
}
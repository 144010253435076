export interface CustomerDetailForm {
    companyName: string,
    sapCustomerId: string,
    internalId: string
}

export const EmptyCustomerForm: CustomerDetailForm = {
    companyName: "",
    sapCustomerId: "",
    internalId: "",
} ;
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { Grid, Button, useTheme, Divider, Typography, Chip, useMediaQuery, Collapse, FormControl } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CustomBox } from "../../../../../Layout/CustomBox";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppSelector, useAppDispatch } from "../../../../../Infrastructure/Store/hooks";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { LegalDocumentsAddRequestDto } from "../Models/LegalDocumentsAddRequestDto";
import { DocumentType } from "../Models/DocumentType";
import { ControlledSelect } from "../../../../../Layout/Inputs/ControlledSelect";
import { mapEnumToOptions } from "../../../../../Infrastructure/Utils/MapperFunctions";
import ControlledReactQuill from "../../../../../Layout/Inputs/ControlledReactQuill";
import { DropzoneArea } from "react-mui-dropzone";
import { AttachFile } from "@mui/icons-material";
import toast from "react-hot-toast";
import { fileToBase64 } from "../../../../../Infrastructure/Utils/FileToBase64";


enum LanguageIsoCode {
    de = "de",
    en = "en"
}

const LegalDocumentsAdd = () => {
    const isDarkMode = useAppSelector((store) => store.settings.theme === "dark");
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery('(max-width:650px)');

    const FILES_LIMIT = 1;

    const [isLoading, setIsLoading] = useState(false);
    const [uploadFile, setUploadFileList] = useState<File | null>(null);
    const navigate = useNavigate();

    const defaultValues: LegalDocumentsAddRequestDto = {
        Content: "",
        DocumentType: DocumentType.PrivacyNotesForRegistration,
        LanguageIsoCode: LanguageIsoCode.de,
        Base64EncodedFile: '',
    };

    const {
        control,
        handleSubmit,
    } = useForm({
        defaultValues: defaultValues,
    });  
    
    const docType = useWatch({
        control,
        name: "DocumentType",
    })

    const onSubmit = async (dataToSend: LegalDocumentsAddRequestDto) => {
        setIsLoading(true);
        let onlyBase64: string | undefined;
        if(uploadFile != null){
            var fileAsBase64 = await fileToBase64(uploadFile);
            onlyBase64 = fileAsBase64.split(",")[1] ? fileAsBase64.split(",")[1] : "";
        }
        else{
            onlyBase64 = undefined;
        }
        try{
            await fetchMyDepragApi<LegalDocumentsAddRequestDto>(
                {
                    ...dataToSend,
                    Content: docType === DocumentType.PrivacyNotesForRegistration ? dataToSend.Content : '',
                    Base64EncodedFile: docType === DocumentType.LegalInformationForDepragGoldPurchase ? onlyBase64 : '',
                }as LegalDocumentsAddRequestDto,
                "legalDocuments/add/",
                "POST",
                dispatch,
                store.getState() as RootState,
                "LegalDocumentsMsgSuccessAdd",
            );
            setIsLoading(false);
            navigate('/legalDocuments');
        }
        catch{
            setIsLoading(false);
        }
    };

    const handleFileDrop = (files: File[]) => {
        if (uploadFile || files.length > 1){
            return
        }
        setUploadFileList(files[0]);
    }

    const handleFileDelete = () => {
        setUploadFileList(null);
    }

    return (
        <LanguageConsumer>
        {({getTranslatedText}) =>
            <Grid container direction="row" spacing={2} className="main-content" sx={{mt: 1}}>
                <Grid item xs={12} sx={{mb: 1, mx: 1}}>
                    <Button color="darky" component={NavLink} to={"/legalDocuments"} variant="outlined">
                        {getTranslatedText("Prev")}
                    </Button>
                </Grid>
    
                <CustomBox
                    elevation={0}
                    sx={{
                        m: 3,
                        pt: 2,
                        height: "100%",
                        width: "100%",
                        backgroundSize: "cover",
                        backgroundBlendMode: "multiply",
                        backgroundColor: isDarkMode ? "transparent" : "#fff",
                        border: `1px solid ${theme.palette.cardBorder.main}`,
                    }}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
           
                        <Grid container direction={"row"} spacing={1} mt={1}>
                            <Grid item xs={6}>
                                <ControlledSelect control={control}
                                    name={"DocumentType"}
                                    label={getTranslatedText("LegalDocumentsDocumentType")}
                                    options={mapEnumToOptions(DocumentType)}
                                    disabled={isLoading}
                                    rules={{required: `${getTranslatedText("LegalDocumentsDocumentType")} ${getTranslatedText("IsRequired")}`}}/>
                            </Grid>
           
                            <Grid item xs={6}>
                                <ControlledSelect control={control}
                                    name={"LanguageIsoCode"}
                                    label={getTranslatedText("LegalDocumentsLanguageIsoCode")}
                                    options={mapEnumToOptions(LanguageIsoCode)}
                                    disabled={isLoading}
                                    rules={{required: `${getTranslatedText("LegalDocumentsLanguageIsoCode")} ${getTranslatedText("IsRequired")}`}}/>
                            </Grid>
                        </Grid>
                        
                        <Collapse in={docType === DocumentType.PrivacyNotesForRegistration} timeout={0}>
                            <Grid>
                                <Divider sx={{my: 4}}/>
                                        
                                <Grid container direction={"column"} spacing={1}>
                                    <Grid item xs={12}>
                                        <ControlledReactQuill name={"Content"}
                                            label={getTranslatedText("LegalDocumentsContent")}
                                            control={control}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Collapse>

                        
                        <Collapse in={docType === DocumentType.LegalInformationForDepragGoldPurchase} timeout={0}>
                            <Grid container direction={"column"}>
                                <Divider sx={{my: 4}}/>
                                
                                <Grid>
                                    <Typography fontWeight={700}>
                                        {getTranslatedText('LegalDocumentsFileAttachment')}
                                    </Typography>
                                </Grid>
                                <Grid>
                                    {!isLoading && 
                                        <DropzoneArea
                                            onChange={handleFileDrop}
                                            onDelete={handleFileDelete}
                                            onAlert={(message) => {
                                                if ((uploadFile) || message === `Maximum allowed number of files exceeded. Only ${FILES_LIMIT} allowed`) {
                                                    toast.error(getTranslatedText("MaxFileLimitSucceded"), { id: "MaxFileLimitSucceded" })
                                                }
                                            }}
                                            filesLimit={FILES_LIMIT}
                                            maxFileSize={1e+7}
                                            clearOnUnmount={true}
                                            dropzoneText={getTranslatedText("LegalDocumentsDragAndDropZoneText")}
                                            showPreviews={false}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                            
                                            onDropRejected={(rejectedFiles) => {
                                                rejectedFiles.forEach(x => {
                                                    if (x.size > 1e+7) {
                                                        toast.error(getTranslatedText("LegalDocumentsFileSizeExceeded"))
                                                    }
                                                })
                                            }}
                                            useChipsForPreview={false}
                                            dropzoneParagraphClass="dropzone-text-ticket"
                                            dropzoneClass="dropzone-body-ticket"
                                            Icon={() => <>
                                                <Button startIcon={<AttachFile />} fullWidth={isMobile} variant="contained" color="success">{getTranslatedText("AddAttachement")}</Button>
                                            </>}
                                        />
                                        }
                                    
                                    {uploadFile && <Grid item xs={12}>
                                        <Chip sx={{mr: 1, mt: 2}} variant="outlined" label={uploadFile.name} onDelete={handleFileDelete} />
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Collapse>
           
                        <Grid container direction={"row"} spacing={2} sx={{mt: 2}}>
                            <Grid item xs={12}>
                                <LoadingButton
                                    type="submit"
                                    color="success"
                                    fullWidth
                                    variant="contained"
                                    disabled={isLoading}
                                >
                                    {getTranslatedText("CommonSave")}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </CustomBox>
            </Grid>
        }
        </LanguageConsumer>
    );
};

export default LegalDocumentsAdd;

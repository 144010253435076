import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { DefaultResponse } from "../../../../../Infrastructure/Api/Model/DefaultResponse";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { CustomerDeleteModel } from "../Models/CustomerDeleteModel";


interface IAllCustomersDeleteDialog {
    open: boolean;
    onClose: () => void;
    customerId: number;
}

export const AllCustomersDeleteDialog = (props: IAllCustomersDeleteDialog) => {

    const theme = useTheme()
    const dispatch = useAppDispatch()
    const language = useAppSelector(store => store.settings.language);
    const langIsDe = useAppSelector(store => store.settings.language === 'de');

    const onDelete= async() => {
        await fetchMyDepragApi<DefaultResponse>({Id: props.customerId} as CustomerDeleteModel, "customers/delete", "POST", 
        dispatch, store.getState() as RootState, "SuccessfullyDeletedCustomerVerification")
    }


    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>
                <Dialog
                    open={props.open}
                    keepMounted
                    onClose={props.onClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{getTranslatedText("DeletingThisCustomer")}</DialogTitle>
                    <DialogContent>
                        {getTranslatedText("AreYouSure")} 
                        <br></br>
                        {getTranslatedText("WantToDeleteThisCustomerID")}  {props.customerId}
                    </DialogContent>

                    <DialogActions>
                        <Button color="error" variant="contained" fullWidth size="large" onClick={onDelete}>{getTranslatedText("Delete")}</Button>
                    </DialogActions>
                </Dialog>
            </>
        }
    </LanguageConsumer>
    )
}

import { createSlice } from '@reduxjs/toolkit';

export type CustomersState = {
    showAllCustomers: boolean,
    generatedCustomerId: number | null
}

export const initialStateCustomers: CustomersState = {
    showAllCustomers: false,
    generatedCustomerId: null
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState: initialStateCustomers,
  reducers: {
    toggleShowAllCustomers: (state) => {
      state.showAllCustomers = !Boolean(state.showAllCustomers)
    },
    setGeneratedCustomerId: (state, action) => {
      state.generatedCustomerId = action.payload;
    },
    clearGeneratedCustomerId: (state) => {
      state.generatedCustomerId = null;
    }
  }
});

export const { clearGeneratedCustomerId, toggleShowAllCustomers, setGeneratedCustomerId } = customersSlice.actions;

export default customersSlice.reducer;
